import { createSlice, PayloadAction, current } from '@reduxjs/toolkit'
import { ItemStatus } from '@engyalo/delivery-ui-components/lib/interfaces/enums'
import {
  SubItem,
  SubItems,
} from '@engyalo/delivery-ui-components/lib/interfaces/catalog'
import type {
  ICatalog,
  ICatalogItem,
  ICategory,
  ICategoryPagination,
  IFreeGood,
  IFreegoodsConf,
  IProduct,
  ISearchResult,
  TBundle,
} from '../../../interfaces'
import {
  setCatalogData,
  setCatalogPagination,
} from '../../../utils/categoryUtils'

export const initialState: ICatalog = {
  categories: [],
  categoryList: [],
  search: {
    term: '',
    items: [],
    status: 'OK',
    loading: false,
    totalSearchStartCount: 0,
  },
  isFetchingMore: false,
  pagination: {},
  selectedProduct: {} as IProduct,
  synchronizingCart: false,
  disabledAddOnBundle: false,
  currentBundleCount: 1,
  bundleCompleted: false,
  freegoodsConf: {
    isConfirmDisabled: true,
    freegoods: [],
  },
  currentBundlePrice: null,
}

export const catalogSlice = createSlice({
  name: 'catalogSlice',
  initialState,
  reducers: {
    setCategoriesPagination: (
      state,
      action: PayloadAction<ICategoryPagination>,
    ) => {
      state.categoriesPagination = action.payload
    },
    setCurrentCategory: (state, action: PayloadAction<ICatalogItem>) => {
      state.currentCategory = action.payload
    },
    setCategories: (state, action: PayloadAction<ICatalogItem[]>) => {
      state.categories = action.payload
    },
    setCategoriesAndPagination: (
      state,
      action: PayloadAction<ICatalogItem[]>,
    ) => {
      state.categories = setCatalogData(action.payload)
      state.pagination = setCatalogPagination(action.payload)
    },
    setCategoryList: (state, action: PayloadAction<ICategory[]>) => {
      state.categoryList = action.payload
    },
    setSynchronizingCart: (state, action: PayloadAction<boolean>) => {
      state.synchronizingCart = action.payload
    },
    setFetchingMoreLoading: (state, action: PayloadAction<boolean>) => {
      state.isFetchingMore = action.payload
    },
    setNewPagination: (state) => {
      state.pagination[state.currentCategory?.slug || ''].pageNumber += 1
    },
    setPaginationAsCompleted: (state) => {
      state.pagination[state.currentCategory?.slug || ''].hasMore = false
    },
    setCurrentBundle: (
      state,
      { payload }: PayloadAction<{ selections: TBundle[]; completed: boolean }>,
    ) => {
      const { completed, selections } = payload
      const reducedSelections =
        selections.length > 0
          ? selections.reduce((acc, obj) => ({
              ...acc,
              ...obj,
            }))
          : {}
      const { currentBundleCount = 1 } = state
      state.disabledAddOnBundle = !(currentBundleCount > 0 && completed)
      state.bundlesCount = reducedSelections
      state.bundleCompleted = completed
    },
    setSubItem: (state, { payload }: PayloadAction<SubItem | null>) => {
      state.subItemEdit = payload
    },
    setSubItems: (state, { payload }: PayloadAction<SubItems>) => {
      state.subItemsList = payload
    },
    setProductDetail: (
      state,
      action: PayloadAction<{ product: IProduct; subItem?: SubItem }>,
    ) => {
      const { product, subItem } = action.payload
      const item = {
        ...product,
        isBundle:
          product.bundle &&
          product.bundle.items &&
          product.bundle.items.length > 0,
        subItem,
      }

      state.disabledAddOnBundle = !subItem
      state.bundlesCount = subItem?.items
      state.selectedProduct = item as any
      state.currentBundleCount = subItem?.quantity || 1
      state.bundleCompleted = !!subItem
      state.currentBundlePrice = ''
    },
    setCurrentBundleCount: (state, action: PayloadAction<number>) => {
      const { bundleCompleted = false } = state
      const disabledButton = action.payload > 0 && bundleCompleted
      state.currentBundleCount = action.payload
      state.disabledAddOnBundle = !disabledButton
    },
    clearProductDetail: (state) => {
      state.selectedProduct = {} as IProduct
    },
    clearCatalogueCart: (state) => {
      const categories = current(state.categories)
      state.categories = categories.map((category: ICatalogItem) => {
        const productsPrices = category.productsPrices
          ? category.productsPrices.map((item) => {
              const newValue = { ...item, quantity: 0 }
              return newValue
            })
          : []
        const newItem = {
          ...category,
          productsPrices,
        }
        return newItem
      })
    },
    updateSearchData: (state, action: PayloadAction<ISearchResult>) => {
      const { term, items = [], loading, status, counter } = action.payload
      state.search = {
        ...state.search,
        term: term || state.search.term,
        items,
        loading,
        status,
        counter,
      }
    },
    clearSearchData: (state) => {
      const { term = '', items } = state.search
      state.search = {
        term: '',
        items: term.trim() ? [] : items,
        loading: false,
        counter: 0,
        status: 'OK',
        totalSearchStartCount: state.search.totalSearchStartCount, // I think this should't be reset
      }
    },
    setRecommendedProducts: (state, { payload }: PayloadAction<IProduct[]>) => {
      state.recommendedProducts = payload
    },
    setCategorySelectedStatus: (
      state,
      { payload }: PayloadAction<ItemStatus>,
    ) => {
      state.categorySelectedStatus = payload
    },
    setFreegoodsConf: (state, { payload }: PayloadAction<IFreegoodsConf>) => {
      state.freegoodsConf = payload
    },
    setAvailableFreegoods: (
      state,
      {
        payload: { isConfirmDisabled, freegoods },
      }: PayloadAction<{ freegoods: IFreeGood[]; isConfirmDisabled: boolean }>,
    ) => {
      state.freegoodsConf.freegoods = freegoods
      state.freegoodsConf.isConfirmDisabled = isConfirmDisabled
    },
    setCurrentBundlePrice: (state, { payload }: PayloadAction<string>) => {
      state.currentBundlePrice = payload
    },
    setCurrentCategoryCatalog: (state, { payload }: PayloadAction<string>) => {
      state.currentCategoryCatalog = payload
    },
  },
})

export const {
  setNewPagination,
  setFetchingMoreLoading,
  setPaginationAsCompleted,
  setCategories,
  setCategoriesAndPagination,
  updateSearchData,
  clearSearchData,
  setProductDetail,
  clearProductDetail,
  clearCatalogueCart,
  setCurrentCategory,
  setSynchronizingCart,
  setCategoryList,
  setCategoriesPagination,
  setRecommendedProducts,
  setCategorySelectedStatus,
  setCurrentBundle,
  setCurrentBundleCount,
  setSubItem,
  setSubItems,
  setFreegoodsConf,
  setAvailableFreegoods,
  setCurrentBundlePrice,
  setCurrentCategoryCatalog,
} = catalogSlice.actions

export default catalogSlice.reducer
